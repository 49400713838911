import React, { useState, useEffect, useRef } from 'react';
import './Modal.css';
import './UserForm.css'
const apiUrl = process.env.REACT_APP_API_URL;

const BASE_URL = `${apiUrl}/business`;

const UserForm = ({ onSubmit, allentered_check, initialData = true, queue_info, auto_queue, usercheck, isPhoneRequired, business_id, get_tables=[] }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    no_of_people: '',
    queue_id: '',
    queue_type: usercheck ? '' : 'waiting',
    estimated_arrival_time: '',
    table_no: '',
    table_id: '',
    remarks: ''
  });
  const nameInputRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedQueueType, setSelectedQueueType] = useState('waiting');
  const [tables, setTables] = useState(get_tables);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const fetchTables = async (token) => {
    try {
      const response = await fetch(`${BASE_URL}/get_business_tables`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setTables(data.data || []);
    } catch (error) {
      console.error('Error fetching tables:', error);
    }
  };
  useEffect(() => {
    
    fetchTables();
  }, []);
  
  
  useEffect(() => {
    if (usercheck) {
      const savedFormData = {
        name: localStorage.getItem('name') || '',
        phone: localStorage.getItem('phone') || '',
        no_of_people: localStorage.getItem('no_of_people') || ''
      };
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...savedFormData
      }));
    }
  }, [usercheck]);

  useEffect(() => {
    // Focus the Name input when the form loads
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);


  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };


  useEffect(() => {
    if (business_id === 10 || business_id === 11) {
      setFormData(prevFormData => ({
        ...prevFormData,
        no_of_people: 1
      }));
    }
  }, [business_id]);

  useEffect(() => {
    if (initialData) {
       // Check if the queue_type is 'reserved' and set the selected queue type
       if (initialData.queue_type === 'reserved') {
        setSelectedQueueType('reserved');
      }
      
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: initialData.customer_name || prevFormData.name || initialData.name,
        phone: initialData.phone_number || prevFormData.phone || initialData.phone,
        no_of_people: initialData.no_of_people || prevFormData.no_of_people,
        queue_type: initialData.queue_type || prevFormData.queue_type,
        table_no: initialData.table_no || prevFormData.table_no,
        estimated_arrival_time: initialData.estimated_arrival_time
          ? (new Date(initialData.estimated_arrival_time).getTime() / 1000) + 19800
          : (prevFormData.estimated_arrival_time 
            ? prevFormData.estimated_arrival_time
            : ''),
        remarks: (initialData.notes?.[0]?.note_text) || prevFormData.remarks || '',
        table_id: initialData.table_id || prevFormData.table_id,
        ...(initialData.queue_type === 'reserved' && {
          queue_id: initialData.queue_id || prevFormData.queue_id,
        }),
      }));
      
    }
  }, [initialData]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    
    if (['name', 'phone', 'no_of_people', 'table_no'].includes(name)) {
      localStorage.setItem(name, value);
    }

    if (name === 'queue_type') {
      setSelectedQueueType(value); 
      if (value === 'reserved') {
        const reservedQueue = queue_info.find(queue => queue.queue_type === 'reserved');
        if (reservedQueue) {
          setFormData(prevFormData => ({
            ...prevFormData,
            queue_id: reservedQueue.queue_id
          }));
        }
      } else if (value === 'waiting') {
        setFormData(prevFormData => ({
          ...prevFormData,
          queue_id: ''
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
  
    let dataToSubmit = { ...formData };
  
    // Remove unnecessary fields based on queue type
    if (usercheck) {
      delete dataToSubmit.table_no;
      delete dataToSubmit.table_id;
    }
  
    if (selectedQueueType === 'waiting') {
      delete dataToSubmit.estimated_arrival_time;
      delete dataToSubmit.remarks;
    }
  
    // Remove queue_id if it is null or an empty string
    if (!dataToSubmit.queue_id) {
      delete dataToSubmit.queue_id;
    }
    if (!dataToSubmit.table_id) {
      delete dataToSubmit.table_id;
    }
  
    try {
      if (allentered_check) {
        onSubmit(dataToSubmit); // Do not await
      } else {
        await onSubmit(dataToSubmit); // Await if allentered_check is false
      }
    } finally {
      setIsSubmitting(false); // Always reset isSubmitting regardless of the condition
    }    
  };
  

  return (
    <div>
    <form onSubmit={handleSubmit} className="user-form">
      <div className="form-group">
        <label>Name:</label>
        <input
          type="text"
          name="name"
          ref={nameInputRef} // Attach the ref to the Name input
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label>Phone:</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          pattern="\d{10}"
          title="Phone number must be 10 digits long"
          placeholder="Enter 10-digit phone number"
          required={isPhoneRequired}
        />
      </div>

      {(business_id !== 10 && business_id !== 11) && (
        <div className="form-group">
          <label>No of People:</label>
          <input
            type="number"
            name="no_of_people"
            value={formData.no_of_people}
            onChange={handleChange}
            required
          />
        </div>
      )}
                  {!usercheck && (
          <>
            {tables.length > 0 ? (
                <div className="form-group">
                  <label>Table:</label>
                  <select
                    name="table_id"
                    value={formData.table_id}
                    onChange={(e) => handleChange({
                      target: {
                        name: 'table_id',
                        value: e.target.value ? parseInt(e.target.value, 10) : null // Ensure value is an integer
                      }
                    })}
                  >
                    <option value="">Select Table (Optional)</option>
                    {tables.map((table) => (
                      <option key={table.id} value={table.id}>
                        {table.name} (Status: {table.status})
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div className="form-group">
                  <label>Table No:</label>
                  <input
                    type="text"
                    name="table_no"
                    value={formData.table_no}
                    onChange={handleChange}
                    placeholder="Enter Table No (Optional)"
                  />
                </div>
              )}
          </>
        )}





      {/* Queue Type dropdown */}
          {!usercheck && (
            <div className="form-group">
              <label>Entry Type:</label>
              <select name="queue_type" value={formData.queue_type} onChange={handleChange} required>
                <option value="">Select Entry Type</option>
                <option value="waiting">Walk-in</option>
                <option value="reserved">Reservation</option>
              </select>
            </div>
          )}


{((usercheck && !auto_queue) || (!usercheck && selectedQueueType === 'waiting' && !auto_queue)) && (
            <div className="form-group">
              <label>Table Seating:</label>
              <select name="queue_id" value={formData.queue_id} onChange={handleChange} required>
                <option value="">Select Table Seating</option>
                {queue_info
                  .filter((queue) => queue.queue_type !== 'reserved') // Filter out 'reserved' queue types
                  .map((queue) => (
                    <option key={queue.queue_id} value={queue.queue_id}>
                      {queue.queue_name}
                    </option>
                  ))}
              </select>
            </div>
          )}



      {/* Conditionally render fields if the queue type is 'reserved' */}
      {selectedQueueType === 'reserved' && (
        <>
          <div className="form-group">
            <label>Estimated Arrival Date and Time:</label>
            <input
              type="date"
              name="estimated_arrival_date"
              value={formData.estimated_arrival_time 
                ? new Date(formData.estimated_arrival_time * 1000).toISOString().split('T')[0]
                : new Date().toISOString().split('T')[0]} // Default to today
              onChange={(e) => {
                const newDate = new Date(e.target.value);
                const currentTime = formData.estimated_arrival_time 
                  ? new Date(formData.estimated_arrival_time * 1000) 
                  : new Date();
                newDate.setHours(currentTime.getHours(), currentTime.getMinutes(), 0);
                setFormData({
                  ...formData,
                  estimated_arrival_time: Math.floor(newDate.getTime() / 1000),
                });
              }}
              required
            />
            <input
              type="time"
              name="estimated_arrival_time"
              value={formData.estimated_arrival_time 
                ? new Date(formData.estimated_arrival_time * 1000).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) 
                : ''}
              onChange={(e) => {
                const { value } = e.target;
                const [hours, minutes] = value.split(":");
                const currentDate = formData.estimated_arrival_time 
                  ? new Date(formData.estimated_arrival_time * 1000)
                  : new Date();
                currentDate.setHours(hours, minutes, 0);
                setFormData({
                  ...formData,
                  estimated_arrival_time: Math.floor(currentDate.getTime() / 1000),
                });
              }}
              required
            />
          </div>

          <div className="form-group">
            <label>Remarks:</label>
            <input
              type="text"
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
            />
          </div>
        </>
      )}

    {usercheck && (
      <div className="terms">
        <label className="terms-label">
          <span style={{ paddingBottom: '4px', color: 'darkslategray' }}>
         By clicking the button below, you agree to our {' '}
            <span className="terms-link" onClick={handleModalToggle}>
              terms and conditions
            </span>
          </span>
        </label>
      </div>
    )}

      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </button>
    </form>

      {isModalOpen && (
        <div className="modal-overlay" onClick={handleModalToggle}>
          <div className="modal-content" style={{ maxWidth: '290px' }} onClick={(e) => e.stopPropagation()}>
            <h2>Terms and Conditions</h2>
            <p class="terms-content">
  By submitting your information in this form, you agree to the following terms:
  <br /><br />
  <strong>Use of Contact Information:</strong><br />
  Your contact details, including your phone number, may be used by us to send transactional updates via WhatsApp, such as order confirmations, status updates, or other related information.
  <br /><br />
  <strong>Customer Assistance:</strong><br />
  The restaurant may contact you in case you leave behind personal belongings or for other customer service purposes.
  <br /><br />
  <strong>Data Privacy:</strong><br />
  Your personal data will be stored securely and will not be shared with third parties without your consent, except as necessary to provide the requested services.
  <br /><br />
  <strong>Opt-Out Option:</strong><br />
  You may opt out of marketing or promotional communications at any time by contacting the restaurant directly.
  <br /><br />
  <strong>Acceptance of Terms:</strong><br />
  By submitting your information, you acknowledge that you have read, understood, and agreed to these terms and conditions.
</p>

            <button onClick={handleModalToggle}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserForm;
