import React, { useState, useEffect } from 'react';
import QueueManager from './components/QueueManager';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import { useLocation } from 'react-router-dom';
import UserPage from './components/UserPage';
import QrCodePage from './components/QrCodePage'; 
import CustomerTrackingPage from './components/CustomerTrackingPage';
import AllEnteredPage from './components/AllEnteredPage';
import TableSettingsPage from './components/TableSettingsPage';
import BusinessSettings from './components/BusinessSettings';
import QueueService from './components/QueueService';
import './App.css';
import { getToken } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";

const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = `${apiUrl}/business`;
const { VITE_APP_VAPID_KEY } = "BC4SBQ1Uo9aB4CMPu93svGk4JLZTcsifaPUVL6ujDMVpLg6v80uOshaMfUXp1FeqSfKGp4_bHnJ8RYaKOu7pSoY";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [businessId, setBusinessId] = useState(null);
  const [businessUserId, setBusinessUserId] = useState(null);
  const [token, setToken] = useState(null);

  

  useEffect(() => {
    const storedAuth = localStorage.getItem('authenticated') === 'true';
    const storedBusinessId = localStorage.getItem('businessId');
    const storedBusinessUserId = localStorage.getItem('businessUserId');
    const storedToken = localStorage.getItem('token');

    if (storedAuth && storedBusinessId && storedBusinessUserId && storedToken) {
      setAuthenticated(true);
      setBusinessId(parseInt(storedBusinessId, 10));
      setBusinessUserId(parseInt(storedBusinessUserId, 10));
      setToken(storedToken);
    }
  }, []);



  async function registerDevice() {
    try {
      const fcm_token = await getToken(messaging, {
        vapidKey: VITE_APP_VAPID_KEY,
      });
      const response = await fetch(`${BASE_URL}/register_device?auth_token=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          entity_id: businessId,
          entity_type: 'business',
          fcm_token: fcm_token,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      return data;

    } catch (error) {
      console.error('Failed to register device:', error);
      return null;
    }
  }

 
  
  function NotificationManager({ businessId, token }) {
    const location = useLocation();
  
    useEffect(() => {
      if (businessId && token && location.pathname !== '/user_form') {
        registerDevice()
        .then(data => {
          if (data) {
            console.log('Device registered successfully:', data);
          }
        });
        requestPermission();
        
      }
    }, [businessId, token, location.pathname]);
  
    async function requestPermission() {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log('Permission granted');
      } else if (permission === "denied") {
        console.log("You denied the notification");
      }
    }
  
    return null; // No UI for this component
  }

  const handleLogin = (business_id, business_user_id, token) => {
    setBusinessId(business_id);
    setBusinessUserId(business_user_id);
    setToken(token);
    setAuthenticated(true);
    localStorage.setItem('authenticated', 'true');
    localStorage.setItem('businessId', business_id.toString());
    localStorage.setItem('businessUserId', business_user_id.toString());
    localStorage.setItem('token', token);
  };

  const handleLogout = () => {
    setAuthenticated(false);
    setBusinessId(null);
    setBusinessUserId(null);
    setToken(null);
    localStorage.removeItem('authenticated');
    localStorage.removeItem('businessId');
    localStorage.removeItem('businessUserId');
    localStorage.removeItem('token');
    localStorage.removeItem('queueStarted');
    QueueService.Logout();
  };



  return (
    <Router>
      <div className="App">
      {
    authenticated && <NotificationManager businessId={businessId} token={token} />
  }
        <Routes>
          <Route
            path="/"
            element={
              !authenticated ? (
                <Login onLogin={handleLogin} />
              ) : (
                <QueueManager 
                  business_id={businessId} 
                  business_user_id={businessUserId} 
                  token={token}
                  handleLogout={handleLogout} 
                />
              )
            }
          />
          <Route 
            path="/user_form" 
            element={<UserPage token={token}/>} 
          />
          <Route 
            path="/qr-code" 
            element={<QrCodePage token={token} business_id={businessId} />} 
          />
          <Route 
            path="/customer_tracking/:final_str" 
            element={<CustomerTrackingPage />} 
          />
          <Route 
            path="/all-entered" 
            element={<AllEnteredPage token={token} business_id={businessId}/>} 
          />
          <Route 
            path="/business-settings" 
            element={<BusinessSettings token={token} business_id={businessId}/>} 
          />
          <Route 
            path="/table-settings" 
            element={<TableSettingsPage token={token} business_id={businessId} />} 
          />
        </Routes>
      </div>
    </Router>
    
  );
}

export default App;
